@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

// TODO: adjust these levels and match z-indexs from Header.module.scss items
$layer1: 9997;
$layer2: 9999;
$layer3: 10000;

$default-margin: 32px;

$navigationSlideTiming: 0.3s;
$navigationFadeTiming: 0.65s;
$navigationFadeDelay: 0.2s;

@mixin mobileStyles {
  .navRoot {
    padding-top: $nav-top-mobile;
    min-width: 330px;
    .navPromoContainer {
      width: 100%;
      display: block;
      padding: 0 20px 15px;
      box-sizing: border-box;

      .navPromo {
        width: 100%;
        aspect-ratio: 291 / 97;
        display: block;
        background-image: var(--promo-bg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  .navPrimary {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: static;
    margin-top: 0;
    padding-left: 0;
  }
  .navSecondary {
    display: block;
    position: relative;
    transition: 0.3s all ease-in;
  }
  .secondaryLink {
    text-align: left;
  }

  .secondaryBackground {
    background: transparent;
    max-height: 0;
    overflow: hidden;
    transition: 0.2s all ease-in;
  }

  .navSecondaryList {
    flex-direction: column;
    flex-wrap: nowrap;
    width: auto;
    justify-content: flex-start;
    background-color: unset;
    margin-left: $default-margin + 10px;
    border-left: 1px solid #4A4A4A;
    .linkTextContainer {
      width: auto;
      height: auto;
    }

    .navLink {
      padding: 8px 0 8px 16px;
    }

    li:first-of-type {
      .navLink {
        padding-top: 0;
      }
    }
    li:last-of-type {
      .navLink {
        padding-bottom: 0;
      }
    }

    &:hover {
      .navLink {
        .linkTextContainer {
          opacity: 1;
        }
      }
    }
  }

  .arrowIconActive {
    transform: rotate(90deg) !important;
  }
  .navigationLabel {
    padding-right: 5px;
  }

  .navLink {
    padding: 15px $default-margin 15px $default-margin;
    border-bottom: 1px solid transparent;
    transition: 0.1s transform ease-in;
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.02em;

    @include media-breakpoint-down(lgg) {
      font-weight: 700;
    }

    .linkTextContainer {
      border: 0;
      border-bottom: 1px solid transparent;
    }

    &:hover {
      .linkTextContainer {
        border-bottom: 1px solid transparent;
      }
    }
    .arrowIcon {
      top: 5px;
    }
    &.activePage {
      .linkTextContainer {
        border: 0;
        border-bottom: 1px solid transparent;
      }
    }
  }
  .linkTextContainer {
    position: relative;
    width: 100%;
  }
  .secondary {
    .imageWrapper {
      picture {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
  .textWrapper {
    position: relative;
    display: block;
  }
  .displayText {
    font-size: 18px;

    @include media-breakpoint-down(lgg) {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.02em;
    }
  }
  .mobileBackButton.buttonVisible {
    display: block !important;
  }
  .navScrollableContent {
    overflow-y: auto;
    height: 100%;
  }  
}

.hideDesktop {
  display: none;

  @include media-breakpoint-down(lgg) {
    display: block;
  }
}

.navRoot {
  position: absolute;
  top: $nav-top-desktop;
  left: 0;
  width: 100%;
  z-index: $layer1;
  background-color: $darkNavy;
  height: $nav-bottom-desktop;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid $temp-gray;

  .navPromoContainer {
    display: none;
  }

  .fade {
    animation: fadeIn 0.3s forwards;
    position: relative;
  }

  .fadeOut {
    animation: fadeOut 0.3s forwards;
    position: relative;
  }

  @include media-breakpoint-down(lgg) {
    z-index: $layer2;
    top: 0;
    opacity: 0;
    height: 100%;
    width: auto;
    max-width: 100%;
    background-color: $darkNavy;
    display: none;
    overflow: auto;
    box-sizing: border-box;
    position: fixed;

    &.showMobile {
      display: block;
      animation: fadeIn 0.3s forwards;
    }
  }
  &.mobileOnly {
    @include mobileStyles;
    z-index: $layer2;
    top: 0;
    left: -550px;
    opacity: 0;
    height: 100%;
    width: 550px;
    max-width: 100%;
    background-color: $ww-black;
    transition: 0.3s all ease-in;
    > ul.navPrimary div.navLink {
      &.openParent + div {
        display: block;
      }
    }
    &.showMobile {
      left: 0;
      opacity: 1;
    }
    &.sidebarClosed {
      display: none;
    }
  }
}

.navPrimary {
  position: relative;
  list-style: none;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 6px; // aligns text better

  @include media-breakpoint-down(lgg) {
    background-color: transparent;
    margin: 0;
    padding: 0;
    max-width: none;
    justify-content: center;
  }

  [data-focus-visible-added] {
    // overwriting js-focus which is off when tabbing into the first nav item after a secondary menu
    outline: $grey-legal-border 1px solid !important;
  }
}

[id^='primary-item'] > div,
a {
  &.closed {
    color: $gold-light;
    opacity: 0.5;

    @include media-breakpoint-down(lgg) {
      opacity: 1;
    }
  }
  &.closed:hover {
    color: $white;
    opacity: 1;
  }
}

.textWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @include media-breakpoint-down(md) {
    position: relative;
    display: inline;
  }

  .dividerContainer {
    margin: 12px 0 24px;
    position: relative;
    width: 80px;

    .leftLine,
    .rightLine {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 0.5px;
      width: 30%;
      background-color: $white;
    }

    .leftLine {
      left: 0;
    }

    .rightLine {
      right: 0;
    }
    
    .unselectedDiamond {
      position: absolute;
      top: 0;
      left: 50%;
      right: 0;
      bottom: 0;
      height: 6px;
      width: 6px;
      margin-left: -2px;
      transform: rotate(45deg) translateX(-50%);
      background-color: transparent;
      border: 0.5px solid $white;
      transition: all 0.2s ease-in-out;
      .diamondInner {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        height: 3px;
        width: 3px;
        transform: translate(-50%, -50%);
        background-color: $white;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  @include media-breakpoint-down(lgg) {
    .dividerContainer {
      display: none;
    }
  }
}

@include media-breakpoint-down(lgg) {
  .imageWrapper,
  .linkTextContainer {
    height: auto !important;
    width: 100% !important;
  }
}

.displayText {
  text-align: center;
  font-family: $sofiapro;
  color: $white;
  font-size: 12px;
  font-weight: 800;
  line-height: 12px;
  letter-spacing: 0px;
}

.imageWrapper {
  height: 140px;
  width: 140px;
  position: relative;

  &:after {
    background-image: linear-gradient(to bottom, rgba($ww-black, 0), $ww-black);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    content: '';
  }
}

.secondary {
  position: relative;
}

.linkTextContainer {
  position: relative;
  bottom: 0;
  border-bottom: 1px solid transparent;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: 0.2s bottom ease-in, 0.2s opacity ease-in;
}

.secondaryLink {
  display: inline-block;
  margin: 0 20px;
  position: relative;

  .linkTextContainer {
    height: 140px;
    width: 140px;
    position: relative;
  }
  a {
    position: relative;
    bottom: 10px;
    border-bottom: 0;
    text-align: center;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    &:hover {
      border-bottom: 0;
    }
  }
}

// shows secondary navigation
.openParent {
  .linkTextContainer {
      &:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 0;
        left: 0;
        bottom: 0px;
        border-bottom: 1px solid $snow;

        @include media-breakpoint-down(lgg) {
          display: none;
        }
      }
  }

  @include media-breakpoint-down(lgg) {
    border-bottom: none;
  }
  
  + div {
    padding-bottom: 10px;
    display: flex;
    flex-flow: column;
    height: 171px;

    @include media-breakpoint-down(lgg) {
      display: block;
      height: auto;
    }
  }
}

.navLink {
  display: block;
  font-family: $sofiapro;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0;
  font-weight: bold;
  padding: 0 10px 3px;
  margin-top: 0;
  color: $white;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  position: relative;


  &:not(.secondaryLink)  {
    .linkTextContainer {
      bottom: 2px;

      @include media-breakpoint-down(lgg) {
        bottom: auto;
      }
    }
  }

  &.activePage {
    .linkTextContainer {
      &:after {
        content: '';
        width: 100%;
        position: absolute;
        height: 0;
        left: 0;
        bottom: 0px;

        @include media-breakpoint-down(lgg) {
          display: none;
        }
      }
    }
    &:not(.secondaryLink)  {
      .linkTextContainer {
        bottom: 2px;

        @include media-breakpoint-down(lgg) {
          bottom: auto;
        }
      }
    }
  }

  @include media-breakpoint-up(lgg) {
    &:hover:not(.secondaryLink) {
      .linkTextContainer {
        &:after {
          content: '';
          width: 100%;
          position: absolute;
          height: 0;
          left: 0;
          bottom: 0px;
          border-bottom: 1px solid $snow;

          @include media-breakpoint-down(lgg) {
            display: none;
          }
        }
      }
    }
  
    &:hover {
      &.secondaryLink {
        .linkTextContainer {
          bottom: 10px;
          opacity: 1 !important;
  
          .unselectedDiamond {
            background-color: $lightMagic;
            border-color: $lightMagic;
            .diamondInner {
              background-color: $lightMagic;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 14px;
    letter-spacing: 0.3px;
  }
}

.navigationLabel {
  @include media-breakpoint-down(md) {
    max-width: 65vw;
    word-wrap: break-word;
  }
}

.secondaryBg {
  height: 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: $nav-bottom-desktop;
  background: $darkNavy;
  transition: height $navigationSlideTiming;
  
  &.showSecondaryBg {
    height: 171px;
  }

  @include media-breakpoint-down(lgg) {
    display: none;
  }
}

.navSecondary {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: $layer2;
  height: 0;
  overflow: hidden;
  
  &.hasOpenParent {
    li {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(lgg) {
    height: auto;
    overflow: visible;
  }
}

.secondaryInner {
  padding: 16px 0 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  
  @include media-breakpoint-down(lgg) {
    padding: 4px 0 12px;
  }
}

.secondaryBackground {
  @include media-breakpoint-down(lgg) {
    background-color: $ww-black;
    position: relative;
  }
}

.darkPageBackground {
  background-color: $black;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9000;
  box-sizing: border-box;

  &.show {
    display: block;
  }
}

.navSecondaryList {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  text-align: center;

  li {
    opacity: 0;
    transition: opacity $navigationFadeTiming $navigationFadeDelay;
  }

  @include media-breakpoint-down(lgg) {
    width: 100%;

    li {
      opacity: 1;
      transition: none;
    }
  }

  .navLink {
    margin: 0;
  }

  &:hover {
    .navLink {
      .linkTextContainer {
        opacity: 0.6;
      }
    }
  }
}

.arrowIcon {
  transform: rotate(90deg);
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 5px;
  transition: 0.2s transform ease-in;
  @include media-breakpoint-down(lgg) {
    transform: rotate(0);
  }
}

.arrowIconActive {
  transform: rotate(-90deg);
  @include media-breakpoint-down(md) {
    transform: rotate(0);
  }
}

.socialShareLinks {
  margin: 0 $default-margin 0;
  padding: 30px 0;
  
  @include media-breakpoint-down(lgg) {
    margin: 20px $default-margin 0;
    border-top: 1px solid rgba($white, 0.2);
  }
}

@include media-breakpoint-down(lgg) {
  @include mobileStyles;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    left: -550px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: -550px;
  }
}
