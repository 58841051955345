.hamburgerBottom {
  transform-origin: 20% bottom;
  stroke-dasharray: 0% 0% 70% 15%;
}

.hamburgerTop {
  stroke-dasharray: 0% 0% 70% 15%;
}

.hamburgerMiddle {
  stroke-dasharray: 0% 0% 100% 0%;
  transform-origin: 25% 50%;
}
