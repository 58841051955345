@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';


.title {
  font-family: $harrybeastregular;
  color: $ww-black;
  font-size: 24px;
  line-height: 32px;

  @include media-breakpoint-up(lg) {
    font-size: 32px;
    line-height: 40px;
  }
}

.description {
  font-family: $sofiapro;
  color: $grey-feature;
  font-size: 14px;
  line-height: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 24px;
  }
}

.link {
  display: block;
  margin: 10px 0;
}

.cancel {
  color: $lightMagic;

  &:hover {
    color: $ww-black !important;
  }
}