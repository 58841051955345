@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hourglass {
  width: 100%;
  height: 100%;
  background: var(--hourglass) 50% 50% no-repeat;
  background-size: 100% 100%;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
  }
  50% {
    transform: rotate(179deg);
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
  }
  100% {
    transform: rotate(359deg);
  }
}
