@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.socialShareLinks {
  display: flex;
  flex-direction: row;
  align-items: center;

  .socialLink {
    display: flex;
    align-items: center;
    margin-right: 14px;

    img {
      height: 24px;
    }
  }

  &.mobile {
    .socialLink {
      margin-right: 26px;
      transform: scale(1.3);
      transform-origin: left;

      img {
        height: unset;
      }
    }
  }
}
