@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$animationDuration: 0.5s;
$startDelay: 0s;
$animationInterval: 0.1s;
$containerFadeDuration: 0.3s;

@function animationDelay($n) {
  @return $startDelay + ($animationInterval * $n);
}

.root {
  position: relative;
  opacity: 0;
  pointer-events: none;
  z-index: 3;
  left: -10px;

  &:focus{
    border: none;
    outline: none;
  } 

  &.visible {
    opacity: 1;
    animation: fadeAndScale $containerFadeDuration forwards 0s;
    pointer-events: all;
  }

  &.shrink {
    animation: fadeOutAndScale $containerFadeDuration forwards;
  }

  &.fadeOutLinks {
    .inner {
      a {
        animation: fadeOut $containerFadeDuration forwards;
      }
    }
  }

  .inner {
    z-index: 3;
    position: absolute;
    top: 17px;
    right: 0;
    width: 200px;
    border: 1px solid $lightMagic;
    background: linear-gradient(180deg, $lightMagic 0%, $darkMagic 100%);
    box-shadow: 0px 3.91px 9.79px 0px $black;
    border-radius: 12px;
    box-sizing: border-box;
    opacity: 0;
    animation: fadeIn $containerFadeDuration forwards 0s;

    & > a {
      padding: 15px 20px 15px 15px;
      display: inline-block;
    }

    &:after, &:before {
      bottom: 100%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: transparent;
      border-bottom-color: $lightMagic;
      border-width: 12px;
      margin-left: -12px;
      right: 26px;
      top: -22px;
    }

    &:before {
      border-color: transparent;
      border-bottom-color: $lightMagic;
      border-width: 12px;
      margin-left: -12px;
      right: 26px;
    }

    .signUpTo {
      font-family: $sofiapro;
      font-size: 11px;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: 0.03em;
      color: $snow;
      text-transform: uppercase;
      text-align: center;
      padding-left: 15px;
    }

    .tiny {
      font-family: $sofiapro;
      font-size: 9px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0.02em;
      text-align: left;
      color: $snow;
      text-transform: uppercase;
    }

    .large {
      font-family: $sofiapro;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.015em;
      text-align: left;
      color: $snow;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      margin: 0 0 0 55px;
      padding: 0;
      opacity: 1;
      transition: opacity $containerFadeDuration;
      
      li {
        position: relative;
        margin: 5px 0;
        padding: 16px 0 6px;
        opacity: 0;

        & + li {
          background: url('/images/divider-diamond.svg') no-repeat 0 0;
          background-size: 100% auto;
        }

        &:first-of-type {
          padding-top: 10px;
        }

        &:nth-child(1) {
          animation: fadeIn $animationDuration forwards animationDelay(1);
        }
        &:nth-child(2) {
          animation: fadeIn $animationDuration forwards animationDelay(2);
        }
        &:nth-child(3) {
          animation: fadeIn $animationDuration forwards animationDelay(3);
        }
        &:nth-child(4) {
          animation: fadeIn $animationDuration forwards animationDelay(4);
        }
      }
    }
  }

  .icon {
    width: 45px;
    height: 45px;
    position: absolute;
    left: -55px;
    top: 11px;
  }

  .icon1 {
    background: var(--icon1) no-repeat 50% 50%;
    background-size: contain;
    top: 6px;
  }

  .icon2 {
    background: var(--icon2) no-repeat 50% 50%;
    background-size: contain;
  }

  .icon3 {
    background: var(--icon3) no-repeat 50% 50%;
    background-size: contain;
  }

  .icon4 {
    background: var(--icon4) no-repeat 50% 50%;
    background-size: contain;
  }
}

.overlay {
  z-index: 2;
  background-color: rgba($black, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  transition: opacity $containerFadeDuration;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  
  &.visible {
    opacity: 1;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeAndScale {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOutAndScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
