@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.root {
  position: relative;
  display: flex;
  align-items: flex-end;

  button {
    z-index: 3;
    position: relative;
    overflow: hidden;

    &:before {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 100px;
      left: 50%;
      background-color: $lightMagic;
      transform: translate(-50%, -50%);
      transition: all 0.3s;
      border-radius: 100%;
      content: "";
      z-index: -1;
    }
  }

  &.isDelayed {
    button {
      animation-play-state: paused;
    }
  }

  &.outlineAnimation {
    button {
      --p:0%;
      border: 1px solid transparent;
      background: linear-gradient($darkNavy, $darkNavy) padding-box, conic-gradient(
        from 300deg,
        $snow 0%,
        $snow var(--p),
        $darkNavy var(--p),
        $darkNavy 100%
      ) border-box;
      animation: 0.5s rotate linear 1 forwards;
    }
  }

  &.fillInButton {
    button {
      border: 1px solid $lightMagic;
      background: transparent;

      &:before {
        width: 130%;
        top: 10px;
      }

      &:hover {
        &:before {
          content: unset;
        }
      }
    }
  }

  svg {
    max-height: 31px;
  }
}

@property --p {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 0%;
}

@keyframes rotate {
  0% {
    --p: 0%;
  }
  100% {
    --p: 100%;
  }
}
