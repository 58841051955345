@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.headerRoot {
  position: fixed;
  width: 100%;
  height: $header-height;
  z-index: 9999;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);

  &.hideNav {
    transform: translateY(-100%);
  }

  @include media-breakpoint-down(lgg) {
    height: $header-height-mobile;
  }
}

.root {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 0;
  height: $nav-top-desktop;
  width: 100%;
  align-items: center;
  background-color: $darkNavy;
  transition: 0.3s background-color ease-in;
  padding: 11px 0 9px;
  z-index: 9998;

  @include media-breakpoint-down(lgg) {
    height: $nav-top-mobile;
  }

  &.blur {
    @include media-breakpoint-down(lgg) {
      @include blurNavigation();
    }
  }

  @media only screen and (max-width: 767px) {
    z-index: 2;
  }
  @media only screen and (max-width: 768px) {
    z-index: 8000;
  }
}

$searchButtonLeftPosition: 192px;

@keyframes fadeIn {
  0% {
    opacity: 0;
    left: -550px;
  }
  100% {
    opacity: 1;
    left: $searchButtonLeftPosition;
  }
}

.searchButton {
  display: none;

  @include media-breakpoint-down(lgg) {
    animation: fadeIn 0.3s forwards;
    display: block;
    left: $searchButtonLeftPosition;
    top: 21px;
    position: absolute;
    z-index: 10000;
  }

  button {
    background: transparent;
    color: $snow;
    display: flex;
    align-items: center;
    border: 1px solid $snow;
    border-radius: 32px;
    padding: 10px 16px;
    cursor: pointer;

    span {
      font-family: $sofiapro;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.84px;
      line-height: 1;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    &:hover {
      background: $snow;
      color: black;
      img {
        filter: invert(1);
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hourglassSpinner {
  width: 18px;
  height: 18px;
  position: relative;
}

.logo {
  display: block;
  height: $logoHeight;
  width: $logoWidth;
  object-fit: contain;

  @include media-breakpoint-down(md) {
    height: $logoHeightMobile;
    width: $logoWidthMobile;
  }
}

.itemLeft {
  position: absolute;
  left: 0;
  margin-left: 100px;

  @include media-breakpoint-down(md) {
    margin-left: 30px;
  }
}

.logoContainer {
  margin: 0 auto;
  width: $logoWidth;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    margin: 0 10px 0 60px;
    width: $logoWidthMobile;
  }
}

.loginBtn {
  @include media-breakpoint-down(md) {
    text-decoration: unset !important;
  }
}

.itemRight {
  right: 0;
  position: absolute;
  margin-right: 25px;
  display: flex;
  align-items: center;

  // overwriting button component styles
  button {
    padding: 9px 16px 10px !important;
    @include media-breakpoint-down(md) {
      padding: 9px 12px 10px !important;
    }
    
    + div > button {
      padding: 9px 22px 10px !important;
      @include media-breakpoint-down(md) {
        padding: 9px 12px 10px !important;
      }
    }
    
    span {
      font-size: 15px;
      line-height: 1;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }
  
  @include media-breakpoint-down(md) {
    margin-right: 5px;
  }
}

.loginButton {
  margin: 0 13px 0 5px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.crest {
  @include btnAllUnset();
  cursor: pointer;
  font-family: $sofiapro;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.07px;
  color: $gold-light;
  padding: 7px;
}

.hamburgerBtn {
  @include btnAllUnset();
  cursor: pointer;
  width: 21px;
  opacity: 0;
  position: absolute;
  left: 31px;
  top: 26px;
  z-index: 10000;
  padding: 0;

  @include media-breakpoint-down(lgg) {
    opacity: 1;
    display: block;
  }
  
  @include media-breakpoint-down(md) {
    left: 18px;
  }

  svg {
    position: relative;
    overflow: overlay;
    margin-top: 5px;
  }

  &.sideBarOpen {
    z-index: 10000;

    &.secondary {
      pointer-events: none;
    }
  }

  &:focus {
    outline: $grey-legal-border 1px solid !important;
  }
}

.socialShareLinks {
  position: absolute;
  top: 0;
  z-index: 10001;
  padding: 40px 28px;
  @include media-breakpoint-down(lgg) {
    display: none;
  }
}
