@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.root {
  position: relative;
  height: 40px;
  width: 40px;

  .crest {
    position: absolute;
    height: 19px;
    width: 19px;
    right: -5px;
    bottom: -5px;
  }

  &.gryffindor {
    background: var(--gryffindorAvatar);
    background-size: contain;
    background-repeat: no-repeat;

    .crest {
      background: var(--gryffindorCrest);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.ravenclaw {
    background: var(--ravenclawAvatar);
    background-size: contain;
    background-repeat: no-repeat;

    .crest {
      background: var(--ravenclawCrest);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.slytherin {
    background: var(--slytherinAvatar);
    background-size: contain;
    background-repeat: no-repeat;

    .crest {
      background: var(--slytherinCrest);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.hufflepuff {
    background: var(--hufflepuffAvatar);
    background-size: contain;
    background-repeat: no-repeat;

    .crest {
      background: var(--hufflepuffCrest);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.noHouse {
    background: var(--noHouseAvatar);
    background-size: contain;
    background-repeat: no-repeat;

    .crest {
      display: none;
    }
  }

  &.hasAvatar {
    background: var(--avatarImage);
    background-size: 165% 165%;
    background-position: 50% 35%;
    background-repeat: no-repeat;
    border-radius: 50%;
    
    // no-house border
    border: 1px solid $lightMagic;

    &.slytherin {
      border: 1px solid $avatarSlytherin;
    }

    &.gryffindor {
      border: 1px solid $avatarGryffindor;
    }

    &.hufflepuff {
      border: 1px solid $avatarHufflepuff;
    }

    &.ravenclaw {
      border: 1px solid $avatarRavenclaw;
    }
  }
}