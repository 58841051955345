@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$input-width: 220px;
$input-padding-right: 40px;
$input-padding: 10px;

.searchInput:focus-within > .termsAndSuggestions {
  display: block;
}

.searchIcon {
  width: 17px;
  height: 17px;
  right: 15px;
  top: 7px;
  position: absolute;
  z-index: 1002;

  img {
    width: 100%;
    height: auto;
  }
}

.termsAndSuggestions {
  position: absolute;
  left: 0;
  top: -2px;
  box-sizing: border-box;
  padding: 0;
  padding-top: 30px;
  background: $darkNavy;
  z-index: 1001;
  animation: fade 0.4s linear;
  border-radius: 18px;
  display: none;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  .termsAndSuggestionsInner {
    box-sizing: border-box;
  }

  &.skipDelay {
    animation: none;
    opacity: 1;
  }

  .noResults {
    font-family: $sofiapro;
    font-size: 12px;
    line-height: 12px;
    padding: 12px 0;
    text-align: center;
    color: $grey-legal-border;
  }

  h3 {
    font-family: $sofiapro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.60);
    margin: 16px 0 4px 0;
    padding-left: $input-padding;
  }

  @include media-breakpoint-down(xm) {
    top: 0;
    position: relative;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      &:last-of-type {
        button:after {
          border-bottom: none;
        }
        button:hover {
          border-bottom: none;
        }
      }

      button {
        font-family: $sofiapro;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $lotion;
        margin: 0;
        border: none;
        background: none;
        cursor: pointer;
        text-align: left;
        padding: 12px $input-padding;
        width: 100%;
        position: relative;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        box-sizing: border-box;

        &:after {
          border-bottom: 1px solid #333349;
          content: '';
          bottom: -2px;
          left: $input-padding;
          right: $input-padding;
          height: 0;
          position: absolute;
        }

        &:hover {
          background: rgba($white, .15);
          border-top: 1px solid $flat;
          border-bottom: 1px solid $flat;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.searchInput {
  display: none;
  position: relative;

  @include media-breakpoint-up(lgg) {
    display: flex;
    position: relative;
    align-items: center;
    margin-right: 10px;

    input {
      width: $input-width;
      height: 29px;
      background: #1A1A2A;
      border-radius: 18px;
      margin: 4px;
      color: $white;
      font-size: 14px;
      font-family: $sofiapro;
      line-height: 14px;
      padding: 0 $input-padding-right 0 $input-padding;
      border: 1px solid transparent;
      box-sizing: border-box;
      z-index: 1002;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-family: $sofiapro;
        font-size: 14px;
        line-height: 14px;
        color: dust;
      }
    }
  }
}

.line {
  display: none;

  @include media-breakpoint-up(lgg) {
    display: block;
    margin: 0 15px 0 15px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}
