@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

@keyframes fadeInScaleUp {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.searchInputMobile {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: $darkNavy;
  z-index: 100000;
  opacity: 0;
  
  display: none;

  &.fadeIn {
    animation: fadeIn 0.3s ease-in-out forwards;
  }

  &.fadeOut {
    animation: fadeOut 0.3s ease-in-out forwards;
  }

  @include media-breakpoint-down(lgg) {
    position: fixed;
    top: 0;
    left: 0px;
    display: flex;
    flex-direction: column;
    background: transparent;
    width: -webkit-fill-available;
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    left: 0;
    display: initial;
    background: $darkNavy;
    width: 100%;
  }
}

.searchInputWrapper {
  padding: 19px 19px 19px 85px;
  background: $black;
  display: flex;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 19px;
  }
}

.inputAndDropdown {
  flex: 1;
  display: flex;
  position: relative;

  @include media-breakpoint-down(md) {
    position: unset;
  }
}

.searchInput {
  flex: 1;
  background: rgba(255, 255, 255, 0.10);
  padding: 9px 10px 9px 40px;
  border-radius: 5px;
  border: 0;
  color: $snow;
  font-weight: 400;
  font-size: 16px;
  font-family: $sofiapro;

  &::placeholder {
    color: #787878;
    font-weight: 400;
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
}

.searchIconWrapper {
  position: absolute;
  padding: 10px;
}

.cancelButton {
  color: $snow;
  font-family: $sofiapro;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  background: transparent;
  border: none;
  margin-left: 8px;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInScaleUp 0.3s ease-in-out forwards;
  cursor: pointer;
}

.trendingLabel {
  h3 {
    padding-top: 25px;
    margin: 10px 0;
    font-family: $sofiapro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    text-transform: uppercase;
    color: $grey-legal-border;

    @include media-breakpoint-down(md) {
      margin: 30px 0 10px;
      padding-top: 0;
    }
  }
}

.termLi {
  margin: 0;
  padding: 16px 0px;
  border-bottom: 0.6px solid $flat;
  cursor: pointer;

  &:active {
    background: #707070;
  }

  &:last-of-type {
    border-bottom: none;
  }

  button {
    font-family: $sofiapro;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background: none;
    color: $lotion;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 0;
  }
}


.searchTermsList {
    list-style-type: none;
    margin: 0;
    padding: 0 30px;
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;

    @include media-breakpoint-down(lgg) {
      background: $deepSatin;
    }

    @include media-breakpoint-down(md) {
      background: transparent;
      top: 80px;
    }
}